import { HttpBareClient } from "@ng-darwin/security";
import { ENDPOINTS } from "./../../environments/environment";
import { ConfigService } from "@ng-darwin/config";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SessionService } from "./session.service";
import { ICorreosFormData } from "../interfaces/correos-form-data";
// insertamos la vble para el root
@Injectable({
  providedIn: "root",
})
// clase principal del modulo
export class DataService extends SessionService {
  // showBackground
  private readonly background = new BehaviorSubject<string>("");
  background$ = this.background.asObservable();
  private readonly formCorreos = new BehaviorSubject<ICorreosFormData>({
    personType: "",
    name: "",
    phone: "",
    email: "",
    productType: "",
    productDesc: "",
    office: "",
    officeDesc: "",
    attentionModel: "",
    preferenceHour: "",
    preferenceDate: "",
    signType: "",
    observations: "",
    sign: "",
  });
  formCorreos$ = this.formCorreos.asObservable();

  // variable con la configuracion base
  public base = this.config.config.app.base;
  public endPoints = ENDPOINTS;
  public url = "";
  public httpHeaders: HttpHeaders = new HttpHeaders({
    "X-Santander-Channel": "INT",
    "Content-Type": "application/json",
  });
  // Parametros del HTTPHeader
  public params: HttpParams = new HttpParams();
  public httpClientMethod: HttpClient | HttpBareClient = this.http;
  public usuarioPC: any[]= this.config.config.app.usuarioPcExcepcionado as any[];
  public usuarioBP: any[] = this.config.config.app.usuarioBpExcepcionado as any[];
  public entorno = this.config.config.app.environment;

  // constructor del modulo
  constructor(
    public http: HttpClient,
    public httpBare: HttpBareClient,
    public config: ConfigService
  ) {
    super();
  }

  /**
   * Metodo para obtener por el
   * get una peticion al API
   * @returns Observable
   */
  getData(): Observable<any> {
    return this.httpClientMethod
      .get(this.base + this.url, {
        headers: this.httpHeaders,
        params: this.params,
      })
      .pipe(
        map((res) => {
          // devuelve el resultado
          return this.processHttpResponse(res);
        })
      );
  }

  // clase para crear elementos
  create(body: object): Observable<any> {
    return this.httpClientMethod
      .post(this.base + this.url, body, {
        headers: this.httpHeaders,
        params: this.params,
      })
      .pipe(
        map((res) => {
          return this.processHttpResponse(res);
        })
      );
  }

  // clase para resetear pass
  reset(body: object): Observable<any> {
    return this.httpClientMethod
      .patch(this.base + this.url, body, {
        headers: this.httpHeaders,
        params: this.params,
      })
      .pipe(
        map((res) => {
          return this.processHttpResponse(res);
        })
      );
  }

  // clase para actualizar elementos
  update(body: object): Observable<any> {
    return this.httpClientMethod
      .put(this.base + this.url, body, {
        headers: this.httpHeaders,
        params: this.params,
      })
      .pipe(
        map((res) => {
          // devuelve el resultado
          return this.processHttpResponse(res);
        })
      );
  }

  // clase para borrar elementos
  delete(): Observable<any> {
    return this.httpClientMethod
      .delete(this.base + this.url, {
        headers: this.httpHeaders,
        params: this.params,
      })
      .pipe(
        map((res) => {
          // devuelve el resultado
          return res;
          // return this.processHttpResponse(res);
        })
      );
  }

  obtainBackgroundImage(actuacion: string) : Observable<any> {
    this.url = this.endPoints.homeWallpaper;
    this.url = this.url.replace("{actuacion}", actuacion);
    this.httpClientMethod = this.http;
    return this.getData();
  }

  /**
   * Metodo que procesa el RESPONSE de las peticiones HttpClien,
   * en este caso inicializamos los HttpParams luego de cada peticion
   * @param response: Object
   * @return response
   */
  processHttpResponse(response: any) {
    this.params = new HttpParams();
    // devuelve el resultado
    return response;
  }

  /**
   * setstorage function
   */
  setStorage(name: string, value: string): void {
    return sessionStorage.setItem(name, value);
  }

  /**
   * getstorage function
   */
  getStorage(name: string): string | null {
    return sessionStorage.getItem(name);
  }

  setBackground(type: string) {
    this.background.next(type);
  }

  setFormCorreos(type: ICorreosFormData) {
    this.formCorreos.next(type);
  }
}
