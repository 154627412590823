import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "presc-comerciales",
    loadChildren: () =>
      import("./modules/presc-comerciales/presc-comerciales.module").then(
        (m) => m.PrescComercialesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "inicio",
    loadChildren: () =>
      import("./modules/inicio/inicio.module").then((m) => m.InicioModule),
    canActivate: [AuthGuard],
  },
  {
    path: "presc",
    loadChildren: () =>
      import("./modules/presc-mapfre/presc-mapfre.module").then(
        (m) => m.PrescriptoresModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "presc-correos",
    loadChildren: () =>
      import("./modules/presc-correos/presc-correos.module").then(
        (m) => m.PrescCorreosModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "presc-digital",
    loadChildren: () =>
      import("./modules/presc-digitales/presc-digitales.module").then(
        (m) => m.PrescDigitalesModule
      ),
      canActivate: [AuthGuard]
  }
];

/**
 * AppRoutingModule
 * Designed to be the root routing module.
 *
 * imports:
 *  - RouterModule. Adds router directives and providers.
 *
 * exports:
 *  - RouterModule. The module with the configured routes.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
