import { UserService } from "./../../services/user.service";
import { LayoutService } from "./../../services/layout.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ROUTE_PATH } from "./../../../environments/environment";
import { SecurityService } from "@ng-darwin/security";
import { HeaderService } from "../../services/header.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DataService } from "./../../../../src/app/services/data.service";

@Component({
  selector: "prescp-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  @ViewChild("anclaAreaPersonal")
  anclaAreaPersonal!: ElementRef;

  public nombre = "Cargando...";
  public contrato = "Cargando...";
  public docIdentidad: any;
  public pass = "**********";
  public telf: any;
  public email: any;
  public doc: any;
  public oficinaMapfre: any;
  public oficinaSantander: any;
  public claveMapf: any;
  public telefOficina: any;
  public emailOficina: any;
  nUser!: string;
  public showUserProfile = false;
  edit!: any;

  inputTextInvalid = true;

  // Actuacion.
  actuacion = sessionStorage.getItem("action");
  public editado = false;

  actuacionStorage = this.actuacion;
  contratoHeader = sessionStorage.getItem("contratoHeader");
  nombreHeader = sessionStorage.getItem("nombreHader");
  nombreUser = sessionStorage.getItem("nUser");
  telfUsuario = sessionStorage.getItem("telefono");
  emailUsuario = sessionStorage.getItem("email");
  documentUser = sessionStorage.getItem("doc");
  ofi = sessionStorage.getItem("oficina");
  oficinaMap = sessionStorage.getItem("oficinaMapfre");
  claveMapfre = sessionStorage.getItem("claveMp");
  oficinaMa = sessionStorage.getItem("oficinaM");
  telfOfi = sessionStorage.getItem("telfOfi");
  emailOfi = sessionStorage.getItem("emailOfi");
  // Logos
  logo1: SafeResourceUrl = "";
  logo2: SafeResourceUrl = "";

  areaPersonalForm = new UntypedFormGroup({
    telefonoP: new UntypedFormControl("", [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(11),
      Validators.pattern(/^\d+$/),
    ]),
  });

  // mensajes
  telefonoOk = "Teléfono actualizado correctamente";
  telefonoError = "Error al insertar el teléfono";
  telefonoVacio = "Error campo vacío";
  telefonoConLetras = "Error no se pueden introducir letras";
  errorLongitud = "El teléfono debe tener una longitud de 9 a 11 dígitos";

  tipoPrescriptor = sessionStorage.getItem('tipoPresc');

  entornoActual = '';
  constructor(
    private readonly router: Router,
    private readonly securityService: SecurityService,
    public _headerService: HeaderService,
    private readonly sanitizer: DomSanitizer,
    private readonly layoutService: LayoutService,
    private readonly userService: UserService,
    private readonly dataService: DataService
  ) {}
  /**
   * metodo
   * ngOnInit
   */
  ngOnInit() {
    this.getLogos();
    this.dataPres();
    this.getEntornoActual();
  }
  dataPres() {
    this.contrato = "Sin datos";
    this.nombre = "Oficina no definida";
    this.actuacion = "";
    this.telf = "Sin datos";
    this.email = "Sin datos";
    this.doc = "Sin datos";
    this.oficinaSantander = "Sin datos";
    this.oficinaMapfre = "Sin datos";
    this.claveMapf = "Sin datos";
    this.nUser = "";
    this.emailOficina = "";
    this.telefOficina = "";

    if (this.contratoHeader) {
      this.contrato = this.contratoHeader;
    }

    if (this.nombreHeader) {
      this.nombre = this.nombreHeader;
    }

    if (this.actuacionStorage) {
      this.actuacion = this.actuacionStorage;
    }

    if (this.telfUsuario) {
      this.telf = this.telfUsuario;
    }

    if (this.emailUsuario) {
      this.email = this.emailUsuario;
    }

    if (this.documentUser) {
      this.doc = this.documentUser;
    }

    if (this.ofi) {
      this.oficinaSantander = this.ofi;
    }

    if (this.oficinaMa) {
      this.oficinaMapfre = this.oficinaMa;
    }

    if (this.claveMapfre) {
      this.claveMapf = this.claveMapfre;
    }

    if (this.nombreUser) {
      this.nUser = this.nombreUser;
    }
    if (this.telfOfi) {
      this.telefOficina = this.telfOfi;
    }
    if (this.emailOfi) {
      this.emailOficina = this.emailOfi;
    }
  }
  routerGoTo(item: string) {
    switch (item) {
      case "home":
        this.comprobarRedireccionamientoHome();
        break;
      case "seguimiento":
        this.router.navigate([ROUTE_PATH.seguimiento]);
        this.dataService.setBackground("sinfondo");
        break;
      case "factura":
        this.router.navigate([ROUTE_PATH.factura]);
        break;
      case "factura2":
        this.router.navigate([ROUTE_PATH.factura]);
        this.dataService.setBackground("sinfondo");
        break;
      case "circuito":
        this.router.navigate([ROUTE_PATH.circuito]);
        break;
      case "doc":
        this.router.navigate([ROUTE_PATH.documentos]);
        break;
      case "news":
        this.router.navigate([ROUTE_PATH.noticias]);
        break;
      case "logout":
        sessionStorage.clear();
        this.layoutService.hideFooter();
        this.securityService.killSession();
        this.router.navigate([ROUTE_PATH.login]);
        break;
      case "prescDigital":
        this.layoutService.hideFooter();
        this.router.navigate([ROUTE_PATH.prescDigital]);
        break;
      default:
        break;
    }
  }

  comprobarRedireccionamientoHome(){
    (this.actuacion === "19" ||
        this.actuacion === "20" ||
        this.actuacion === "21") && !this.checkPrescComGenerico() && !this.checkPrescBancaPrivada()
          ? this.router.navigate([ROUTE_PATH.inicioProductos])
          : this.router.navigate([ROUTE_PATH.inicioPcomerciales]);
    if(this.checkPrescComGenerico()){
      this.router.navigate([ROUTE_PATH.inicioProductos]);
    }
    if(this.checkPrescBancaPrivada()){
      this.router.navigate([ROUTE_PATH.inicioProductos]);
    }
  }

  getLogos() {
    if (!this.checkPrescBancaPrivada() && this.actuacion !== "21") {
      this._headerService
        .getLogos(this.actuacion)
        .subscribe((response: any) => {
          if (response.logo1) {
            this.logo1 = this.transform(response.logo1);
          }
          if (response.logo2) {
            this.logo2 = this.transform(response.logo2);
          }
        });
    }
  }


  transform(img: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpeg;base64," + img
    );
  }
  editProfile() {
    this.edit = document.getElementById("editar");

    if (this.editado === true) {
      this.editado = false;
      this.edit.innerHTML = "Editar";
      this.areaPersonalForm.reset();
    } else {
      this.editado = true;
      this.edit.innerHTML = "Volver";
    }
  }
  guardarAreaP() {
    this.editado = false;
    this.edit.innerHTML = "Editar";
    const telefono = this.areaPersonalForm.value.telefonoP;
    const regexNum = /[^0-9]/;
    if (telefono === null || telefono === "") {
      this.enviarMensajeError(this.telefonoVacio);
    } else if (telefono.match(regexNum)) {
      this.enviarMensajeError(this.telefonoConLetras);
    } else{
      if (telefono.length > 8 && telefono.length < 12) {
        this._headerService.setAreaPersonal(telefono).subscribe(
          (response: any) => {
            this.enviarMensajeOk(this.telefonoOk);
            this.getPhone();
          },
          (err) => {
            this.enviarMensajeError(this.telefonoError);
          }
        );
      } else {
        this.enviarMensajeError(this.errorLongitud);
      }
    }
    this.areaPersonalForm.reset();
  }
  /**
   * getPhone.
   */
  getPhone() {
    this.userService.getContactData().subscribe(async (response: any) => {
      this.telf = response.methodResult.telefono;
      sessionStorage.setItem("telefono", this.telf);
    });
  }

  navegarCambiarPass() {
    this.layoutService.hideHeader();
    sessionStorage.clear();
    this.securityService.killSession();
    this.router.navigate([ROUTE_PATH.recoveryPass]);
  }

  navegarCambiarPassWToken() {
    this.layoutService.hideHeader();
    this.layoutService.showResetPass();
    this.router.navigate([ROUTE_PATH.login]);
  }
  /**
   * EnviarMensajeOk
   */
  enviarMensajeOk(mensaje: string) {
    this.layoutService.showAlert({
      textDescription: mensaje,
      textBtnClose: "De acuerdo",
      classIcon: "icon-success",
    });
  }
  /**
   * EnviarMensajeError
   */
  enviarMensajeError(mensaje: string) {
    this.layoutService.showAlert({
      textDescription: mensaje,
      textBtnClose: "De acuerdo",
      classIcon: "icon-error",
    });
  }

  showProfile() {
    this.showUserProfile = !this.showUserProfile;
    this.editado = false;
    this.areaPersonalForm.reset();
  }

  getEntornoActual() {
    this.entornoActual = this.dataService.entorno!.toString() as string;
  }

  checkPrescComGenerico(){
    if(this.tipoPrescriptor === 'comercial'){
      return true;
    }else{
      return false;
    }
  }

  checkPrescBancaPrivada(){
    if(this.tipoPrescriptor === 'privada'){
      return true;
    }else{
      return false;
    }
  }

}
